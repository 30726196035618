import { CircularProgress, Container, Fade, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableProps, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { RowDataModel, RowModelTypes } from "../../models/tablerowdatamodel";
import SortTableHead from "./sort_table_head";
import SortTableBody from "./sort_table_body";

export default function SortTable({ documents, isDataLoading, handleBodyCellClicked, props }: {
    documents: RowDataModel[],
    isDataLoading: boolean,

    props: TableProps
    handleBodyCellClicked: (key: keyof RowDataModel, tail: string) => void
}) {
    const headCells = [
        'tail',
        'pos',
        'bos',
        'ios',
        'qti',
    ] as RowModelTypes

    const [sortOrderKey, setSortOrderKey] = useState<keyof RowDataModel>();
    const [sortOrder, setSortOrder] = useState<ORDER_TYPE>(ORDER_TYPE.ASC);

    const handleHeadCellClicked = (key: keyof RowDataModel) => {
        setSortOrderKey(key)
        setSortOrder((sortOrder == ORDER_TYPE.ASC) ? ORDER_TYPE.DESC : ORDER_TYPE.ASC)

        return { key: key, order: sortOrder }
    }

    return (
        <>
            <TableContainer style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Table {...props}>
                    <SortTableHead headCells={headCells} cellClickedCallback={handleHeadCellClicked} />
                    <SortTableBody headCells={headCells} documents={documents} sortKey={sortOrderKey!} order={sortOrder} cellClickedCallback={handleBodyCellClicked} />
                </Table>
            </TableContainer>
            <Stack height={"100%"} width={"100%"} alignItems={"center"}>
                <Fade in={isDataLoading} unmountOnExit={true} >
                    <CircularProgress />
                </Fade>
            </Stack>
        </>
    )
}

export const enum ORDER_TYPE {
    ASC,
    DESC
}
