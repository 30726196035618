import { createTheme } from "@mui/material"

const myTheme = createTheme({
    palette: {
        primary: {
            main: '#FF4444'
        },
        secondary: {
            main: '#FF6666'
        }
    }
})

export default myTheme;