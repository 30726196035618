import { Route, Routes, useRoutes } from "react-router-dom";
import Main from "../pages/main";

export default function Router() {

    // return useRoutes ([
    //     {
    //         path: "/",
    //         element: <Main />,
    //     }
    // ]);


    return (
        <Main/>
    )
}