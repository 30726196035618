import FormIcon from '@mui/icons-material/InsertDriveFile';
import { Home, OilBarrel } from '@mui/icons-material';
import OilRep from '../pages/oilrep';
import MM_Pairs from '../pages/mm_pairs';
import HomeIcon from '@mui/icons-material/Home';
import GeneralTracking from '../pages/generaltracking';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import Page from '../models/pages';
import ServicingTime from '../pages/servicing_time';
const pages: Page[] = [
    {
        name: 'Home',
        icon: <HomeIcon />,
        href: '/',
        element: <Home />
    }, {
        name: 'Oil Replenishment',
        icon: <OilBarrel />,
        href: '/oilrep',
        element: <OilRep />
    }, {
        name: 'MM_Pairs',
        icon: <FormIcon />,
        href: '/mmpairs',
        element: <MM_Pairs />
    }, {
        name: 'General Tracking',
        icon: <BubbleChartIcon />,
        href: '/generaltracking',
        element: <GeneralTracking />
    }, {
        name: 'Servicing Times',
        icon: <BubbleChartIcon />,
        href: '/servicingtimes',
        element: <ServicingTime />
    }
];

export default pages;