import { Home } from "@mui/icons-material";
import Page from "../models/pages";
import SigninPage from "../pages/auth/signin";

const authPages: Page[] = [
    {
        name: 'Sign In',
        href: '/signin',
        element: <SigninPage />,
        icon: null
    }
];

export default authPages;