import { firestoreDB } from "../firebase";
import { collection, doc, setDoc, arrayUnion, Timestamp, updateDoc, addDoc, deleteDoc, query, QueryFieldFilterConstraint, getDocs, startAfter, startAt, limit, DocumentSnapshot, orderBy, QuerySnapshot, getDoc, where } from "firebase/firestore";

export function validateDocumentSegments(path: string) {
    const pathArray = path.split('/');

    // filter array
    const tempArray: string[] = [];
    pathArray.forEach(element => {
        if (element.length > 0) {
            tempArray.push(element);
        }
    });

    // check path segments
    if (tempArray.length % 2 != 0) {
        throw ('path has odd amount of segments');
    }
}

export async function updateDocument({ path, data }:
    { path: string, data: any }) {
    console.log(data);
    validateDocumentSegments(path);

    const docRef = doc(firestoreDB, path);

    const promise = updateDoc(docRef, data);

    return promise;
}

export async function deleteDocument(path: string) {
    validateDocumentSegments(path);

    const docRef = doc(firestoreDB, path);
    const promise = deleteDoc(docRef);

    console.log(promise);
    return promise;
}

export async function getCollectionsOfDocument({ path }: { path: string }) {
    const docRef = getDoc(doc(firestoreDB, path));

    await docRef.then((docSnapshot) => {
        console.log(docSnapshot);
    })
}

export async function addDocument(data: any, path: string) {
    const docRef = setDoc(doc(firestoreDB, path), data);

    return docRef
}