import { Autocomplete, AutocompleteRenderInputParams, Container, Fab, List, ListItem, Stack, TextField, Typography, colors } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import AircraftInfoCard from "../components/aircraft-info-card";
import { useEffect, useState } from "react";
import { aircraftList } from "../constants/aircraft-list";
import TrackingFormDialog from "../components/tracking-form-dialog";
import { GTModel } from "../models/gtmodel";
import { addGeneralTrackingDocument } from "../usecases/addGeneralTrackingDocument";
import { Timestamp, onSnapshot } from "firebase/firestore";
import getGeneralTrackingDocsQuery from "../usecases/getGeneralTrackingDocs";


export default function GeneralTracking() {

    const [selectedAircraft, setSelectedAircraft] = useState('');
    const [formDialogState, setFormDialogState] = useState(false);
    const [trackItemList, setTrackItemList] = useState<Array<GTModel>>();

    const [updateDataFromFirebase, setUpdateDataFromFirebase] = useState(true);

    useEffect(() => {
        let dataArray: GTModel[] = [];

        getGeneralTrackingDocsQuery().then((querySnapshot) => {
            onSnapshot(querySnapshot, (documentSnapshot) => {
                documentSnapshot.docs.forEach((document) => {
                    const data = document.data();
                    const model = {
                        tail: data['tail'],
                        description: data['description'],
                        timestamp: data['timestamp'],
                        username: data['username'],
                        documentId: document.id,
                        isPinned: data['isPinned']
                    }

                    dataArray.push(model);
                })
                setTrackItemList(dataArray);
                dataArray = [];
            })
        })
    }, []);


    const openFormDialog = () => {
        setFormDialogState(true);
    }
    const closeFormDialog = () => {
        setFormDialogState(false);
    }

    const handleFabClicked = () => {
        openFormDialog();
    }

    const handleDialogSubmit = (tail: string, description: string) => {
        setUpdateDataFromFirebase(!updateDataFromFirebase);
        closeFormDialog();

        addGeneralTrackingDocument({
            data: {
                tail: tail,
                description: description,
                timestamp: Timestamp.fromDate(new Date()),
                username: 'Emek',
                isPinned: false
            }
        });
    }

    const handleAircraftChangedEvent = (id: string) => {
        setSelectedAircraft(id);
    }

    return (
        <Container
            sx={{
                paddingTop: 1
            }}>
            <Stack spacing={2}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={aircraftList}
                    sx={{ width: '100%' }}
                    renderInput={(params) => {
                        return (<TextField {...params} label="Aircraft" />)
                    }}
                    onChange={(e, value) => {
                        handleAircraftChangedEvent(value?.label ?? '')
                    }}
                />

                <List>
                    {
                        trackItemList?.map((item, index) => {
                            if (item.tail == selectedAircraft || selectedAircraft == '')
                                return (
                                    <ListItem key={index}
                                        sx={{
                                            marginBottom: 2
                                        }}
                                        disablePadding>
                                        <AircraftInfoCard
                                            data={item} />
                                    </ListItem>
                                )
                        })
                    }
                </List>
                <Fab
                    color="primary"
                    sx={{
                        position: 'fixed',
                        bottom: 32,
                        right: 32
                    }}
                    onClick={() => {
                        handleFabClicked();
                    }}
                >
                    <AddIcon />
                </Fab>
            </Stack>
            <TrackingFormDialog
                open={formDialogState}
                onClose={() => { closeFormDialog() }}
                onSubmit={(tail, description) => {
                    handleDialogSubmit(tail, description);
                }}
            />
        </Container>
    )
}