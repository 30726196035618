import { Button, Container, Stack, TextField, Typography } from "@mui/material";
import SignInWithEmailAndPass from "../../usecases/auth/signInWithEmailAndPass";
import { useState } from "react";

export default function SigninPage() {


    const [name, setName] = useState<string>();
    const [password, setPassword] = useState<string>();

    return (
        <Container>
            <Stack width={"100%"} height={"100%"} alignItems={"center"} paddingTop={"35px"} spacing={"12px"}>
                <Typography
                    variant="h6">
                    Sign in.
                </Typography>
                {/* Phone number */}
                <TextField
                    fullWidth
                    id="signin-email"
                    label="Email"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                    }} />
                {/* Password */}
                <TextField
                    fullWidth
                    id="signin-password"
                    label="Password"
                    type="password"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPassword(event.target.value);
                    }} />

                {/* submit button */}
                <Button
                    fullWidth onClick={() => {
                        SignInWithEmailAndPass({ email: `${name}`, password: `${password}` })
                    }}> Submit </Button>
            </Stack>
        </Container>
    )
}