import { Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { aircraftList } from "../constants/aircraft-list";
import { getCollectionsOfDocument, updateDocument } from "../repositories/firestore";

export default function TrackingFormDialog(
    { open, onClose, onSubmit }:
        { open: boolean, onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void, onSubmit: (tail: string, description: string) => void }) {

    const [aircraft, setAircraft] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    function clearFields() {
        setAircraft('');
        setDescription('');
    }

    const handleAircraftChangedEvent = (event: SelectChangeEvent<string>) => {
        setAircraft(event.target.value);
    };
    const handleDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDescription(e.target.value)
    }
    const handleSubmitButton = () => {
        clearFields();
        onSubmit(aircraft, description);
    }
    const handleUpdateClicked = () => {
        clearFields();
        updateDocument({
            path: `116/generaltracking/${aircraft}/dwk8VnzhOkmTUXhEzMsK`,
            data: {
                description: description
            }
        })
    }
    return (
        <Dialog
            maxWidth="lg"
            fullWidth={true}
            open={open}
            onClose={onClose}>
            <DialogContent>

                <Stack spacing={1.5}>
                    <FormControl>
                        <InputLabel id="tail-select-label-dialog">Tail Number</InputLabel>
                        <Select
                            labelId="tail-select-label-dialog"
                            value={aircraft}
                            label="Tail Number"
                            onChange={handleAircraftChangedEvent}
                        >
                            {aircraftList.map((item) => (
                                <MenuItem key={item.label} value={item.label}>{item.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        multiline
                        rows={4}
                        id="gf-description"
                        lang={'he'}
                        dir={"rtl"}
                        label="Description"
                        variant="outlined"
                        onChange={handleDescriptionChanged}
                        value={description}
                    />
                    <Button
                        variant="contained"
                        onClick={handleSubmitButton}>
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => { getCollectionsOfDocument({ path: "116/generaltracking" }) }}>
                        Update
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog >
    )

}