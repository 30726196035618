import { DocumentData } from "firebase/firestore";
import { RowDataModel } from "../models/tablerowdatamodel";

export function FirestoreToRowModelConverter(data: DocumentData, id: string) {
    return {
        pos: data['pos'],
        bos: data['bos'],
        ios: data['ios'],
        qti: data['qti'],
        tail: id
    } as RowDataModel
}