import { TableCell, TableHead, TableRow } from "@mui/material"
import { RowDataModel, RowModelTypes } from "../../models/tablerowdatamodel"
import { ORDER_TYPE } from "./sort_table"
import { useState } from "react"

export default function SortTableHead({ headCells, cellClickedCallback }:
    { headCells: RowModelTypes, cellClickedCallback: (key: keyof RowDataModel) => { key: keyof RowDataModel, order: ORDER_TYPE } }) {

    const [orderKey, setOrderKey] = useState<keyof RowDataModel>();
    const [orderType, setOrderType] = useState<ORDER_TYPE>();

    return (<TableHead>
        <TableRow style={{ backgroundColor: "#EEEEEE" }}>
            {headCells.map((key) => {
                return (<TableCell key={key} onClick={() => {
                    // cellClicked will return the perferred order and order type(asc, desc)
                    const orderData = cellClickedCallback(key)
                    setOrderKey(orderData.key)
                    setOrderType(orderData.order);
                }}>
                    {key.toUpperCase()} {(key == orderKey) ? (orderType == ORDER_TYPE.DESC) ? "↑" : "↓" : ""}
                </TableCell>)
            })}
        </TableRow>
    </TableHead>)

}