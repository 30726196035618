import { Box, Card, CardActions, CardContent, CardHeader, Container, Divider, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { IsHebrew } from "../util/ishebrew";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import PushPinIcon from '@mui/icons-material/PushPin';
import { useState } from "react";
import { GTModel } from "../models/gtmodel";

export default function AircraftInfoCard(
    { data }:
        { data: GTModel }) {

    let isHebrew = IsHebrew(data.description);

    const [actionState, setActionState] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleActionClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
        setActionState(!actionState);
        setAnchorEl(event.currentTarget);
    }

    const handlePinClicked = async (isPinned: boolean) => {
        // TODO() update handlePinClicked
    }
    return (
        <Card
            elevation={3}
            sx={{
                width: '100%'
            }}>
            <CardHeader
                title={data.tail}
                titleTypographyProps={{
                    fontFamily: 'segoe UI',
                    fontWeight: 'bold'
                }}
                subheader={data.username}
                action={
                    <IconButton
                        id="action-button"
                        aria-label="settings"
                        aria-controls={'card-menu'}
                        aria-haspopup="menu"
                        aria-expanded={actionState ? 'true' : undefined}
                        onClick={
                            handleActionClicked
                        }
                    >
                        <MoreVertIcon />
                    </IconButton>
                }
            />
            <Stack
                sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingBottom: 2
                }}
                spacing={1}>
                <Typography
                    align={isHebrew ? "right" : "inherit"}
                    variant="body1">
                    {data.description}
                </Typography>
                <Divider />
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}>
                    <Typography
                        variant="caption">
                        {data.timestamp.toDate().toLocaleString('he')}
                    </Typography>
                    {
                        (data.isPinned) ? <Icon fontSize="small">
                            <PushPinIcon />
                        </Icon> : <div></div>
                    }
                </Stack>
            </Stack>
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'action-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={() => { setActionState(false); }}
                id="card-menu"
                anchorEl={anchorEl}
                open={actionState}>
                <MenuItem>
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Delete
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                    handlePinClicked(data.isPinned);
                }}>
                    <ListItemIcon>
                        <PushPinIcon />
                    </ListItemIcon>
                    <ListItemText>
                        {data.isPinned ? "Unpin" : "Pin"}
                    </ListItemText>
                </MenuItem>
            </Menu>

        </Card>
    )
}

