export const aircraftList = [
    {label: '902'},
    {label: '905'},
    {label: '907'},
    {label: '909'},
    {label: '918'},
    {label: '928'},
    {label: '930'},
    {label: '932'},
    {label: '939'},
    {label: '941'},
    {label: '943'},
    {label: '945'},
    {label: '944'},
    {label: '946'},
    {label: '949'},

]