import { Button, Dialog, DialogContent, DialogTitle, Divider, FormControl, Stack, TextField } from "@mui/material";
import { DateField, DateTimeField, DateTimeValidationError, TimeField } from "@mui/x-date-pickers";
import { RowDataModel, validateRowDataModel } from "../models/tablerowdatamodel";
import dayjs, { Dayjs } from "dayjs";
import { FieldChangeHandler } from "@mui/x-date-pickers/internals";
import { ChangeEvent } from "react";

export function MyDateTimeField(
    { label, onChange }: { label: string, onChange: (value: any) => void }
) {
    return (
        <DateTimeField format="DD/MM/YYYY HH:mm" ampm={false} label={label} onChange={onChange} />
    )
}

export default function AddAircraftTableDialog(
    { open, onClose, onSubmit }:
        {
            open: boolean,
            onClose: () => void,
            onSubmit: (data: RowDataModel) => void
        }
) {

    let data: RowDataModel = {
        pos: -1,
        bos: -1,
        ios: -1,
        qti: 0
    } as RowDataModel;

    const onSubmitClicked = () => {
        const validateData = validateRowDataModel(data)
        if (validateData.isValid) {
            onSubmit(data)
        } else {
            // snackbar
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                Add Aircraft
            </DialogTitle>

            <DialogContent>
                <Stack spacing={1} paddingTop={1}>
                    <TextField label="Tail" type="number" onChange={(event) => {
                        const value = event.target.value;

                        if (value) {
                            data.tail = value
                        }
                    }} />
                    <MyDateTimeField label="POS" onChange={(value) => {
                        console.log(value)
                        const unixTime = (value as Dayjs)
                        if (unixTime) {
                            data.pos = unixTime.unix()
                        }
                    }} />
                    <MyDateTimeField label="BOS" onChange={(value) => {
                        const unixTime = (value as Dayjs)
                        if (unixTime) {
                            data.bos = unixTime.unix()
                        }
                    }} />
                    <MyDateTimeField label="IOS" onChange={(value) => {
                        const unixTime = (value as Dayjs)
                        if (unixTime) {
                            data.ios = unixTime.unix()
                        }
                    }} />
                    <TextField label="QTI" type="number" onChange={(event) => {
                        const qti = event.target.value;
                        if (qti) {
                            data.qti = parseInt(qti)
                        }
                    }} />
                    <Divider />

                    <Button variant="outlined" color="inherit" onClick={onSubmitClicked}>Add Aircraft</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )

}