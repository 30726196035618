import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Container, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Page from '../models/pages';
import { AccountCircle, Logout } from '@mui/icons-material';
import SignOut from '../usecases/auth/signout';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';



function ResponsiveAppBar({ location, pagesList }:
    { location: string, pagesList: Page[] }) {

    const [drawerState, setDrawerState] = React.useState(false);
    const navigate = useNavigate();
    return (
        // <Box>
        <AppBar >
            <Toolbar>
                <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                    onClick={() => {
                        setDrawerState(!drawerState);
                    }}
                    sx={{ mr: 1 }}>
                    <MenuIcon />
                </IconButton>

                <Typography
                    flexGrow={1}
                    variant='h6'
                    letterSpacing={'.1rem'}
                    fontFamily={'monospace'}>
                    {location}
                </Typography>


                {getAuth().currentUser ? <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                    onClick={() => {
                        SignOut()
                        navigate('/signin')
                    }}>
                    <Logout />
                </IconButton> : <div></div>}


                <SwipeableDrawer
                    anchor='left'
                    open={drawerState}
                    onClose={() => { setDrawerState(false) }}
                    onOpen={() => { setDrawerState(true) }}
                >
                    <Box padding={1}>
                        <List>
                            {
                                pagesList.map((page, index) => {
                                    return (
                                        <ListItem
                                            key={page.name}
                                            disablePadding>
                                            <ListItemButton
                                                onClick={() => {
                                                    navigate(page.href)
                                                    setDrawerState(!drawerState)
                                                }}
                                            >
                                                <ListItemIcon>
                                                    {page.icon ?? <QuestionMarkIcon />}
                                                </ListItemIcon>
                                                <ListItemText primary={page.name} />
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </Box>
                </SwipeableDrawer>
            </Toolbar>
        </AppBar>
        // </Box>
    );
}
export default ResponsiveAppBar;