import { Container, List, ListItem, Stack, Typography } from "@mui/material";
import MMPairsCard from "../components/mm_pairs_card";
import { useEffect, useState } from "react";
import { GetMMPairsFromFirebase } from "../usecases/getmmpairs";
import { MMPairModel } from "../models/MMPairModel";

export default function MATemplataes() {

    const [pairsList, setPairsList] = useState<Array<MMPairModel>>()

    useEffect(() => {
        let dataArray: MMPairModel[] = []

        GetMMPairsFromFirebase().then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.data()

                Array.from(data['pairs']).map((value) => {
                    dataArray.push((value as MMPairModel))
                })

                setPairsList(dataArray)

            }
        }).catch((error) => {
            throw error
        })
    }, [])

    return (
        <Container>
            <List>
                {
                    pairsList?.map((model, index) => {
                        return (
                            <ListItem key={index} sx={{paddingLeft: "8px", paddingRight: "8px"}}>
                                <MMPairsCard model={model} />
                            </ListItem>
                        )
                    })
                }
            </List>
        </Container >
    )
}