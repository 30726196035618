export type RowDataModel = {
    tail: string;
    pos: number;
    bos: number;
    ios: number;
    qti: number;
}

export function validateRowDataModel(T: RowDataModel): { isValid: boolean, error: string } {
    let isValid = true;
    let errorMessage = '';

    if (T.qti == undefined || NaN || null || T.qti > 5) {
        isValid = false;
        errorMessage = "QTI is invalid."
    }
    if (!T.tail || T.tail.length == 0) {
        isValid = false;
        errorMessage = "Tail is invalid."
    }

    return { isValid: isValid, error: errorMessage }
}

export type RowModelTypes = (keyof RowDataModel)[];