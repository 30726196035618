import styled from "@emotion/styled";
import { Button, Card, Container, Dialog, DialogContent, DialogContentText, DialogTitle, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import ResponsiveAppBar from "../components/ResponsiveAppBar";

export default function OilRep() {

    const [serialNumber, setSerialNumber] = useState<number>();
    const [beforeServicing, setBeforeServicing] = useState<number>();
    const [afterServicing, setAfterServicing] = useState<number>();
    const [replenishedQuantity, setReplenishedQuantity] = useState<number>();

    const [dialogState, setDialogState] = useState(false);

    const handleClickOpen = () => {
        setDialogState(true);
    }
    const handleDialogClose = () => {
        setDialogState(false);
    }

    const serialNumberChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = parseFloat(event.target.value);
        setSerialNumber((input >= 0) ? input : undefined)
    }
    const beforeServicingChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = parseFloat(event.target.value);
        setBeforeServicing((input >= 0) ? input : undefined)
    }
    const afterServicingChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = parseFloat(event.target.value);
        setAfterServicing((input >= 0) ? input : undefined)
    }
    const replenishedQuantityChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = parseFloat(event.target.value);
        setReplenishedQuantity((input >= 0) ? input : undefined)
    }

    function calcualteReplenishment(): number {
        if (afterServicing == undefined || beforeServicing == undefined) {
            return -1;
        }
        const quartsService = (afterServicing - beforeServicing) / 32;
        return (replenishedQuantity ?? -1) - quartsService;
    }

    function generateText(): string {
        return "Replenished " + calcualteReplenishment() + " from cart " + serialNumber;
    }

    return (
        <>
            {/* <ResponsiveAppBar /> */}
            <Container>
                <Stack spacing={2} marginTop={2}>
                    <Typography align="center" gutterBottom fontWeight={700}>
                        Oil Replenishing Calculator
                    </Typography>
                    {/* Serial Number Text Field */}
                    <TextField
                        id="serial-number"
                        label="Serial Number"
                        variant="outlined"
                        inputProps={{ inputMode: 'decimal' }}
                        onChange={serialNumberChangedHandler}
                        value={serialNumber ?? ''}
                    />
                    {/* Quantity Before Servicing Text Field */}
                    <TextField
                        id="before-servicing"
                        label="Before servicing"
                        variant="outlined"
                        type="number"
                        inputProps={{ inputMode: 'decimal'}}
                        onChange={beforeServicingChangedHandler}
                        value={beforeServicing ?? ''}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">Oz</InputAdornment>
                        }}
                    />
                    {/* Quantity After Servicing Text Field */}
                    <TextField
                        id="after-servicing"
                        label="After servicing"
                        variant="outlined"
                        type="number"
                        inputProps={{ inputMode: 'decimal'}}
                        onChange={afterServicingChangedHandler}
                        value={afterServicing ?? ''}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">Oz</InputAdornment>
                        }}
                    />
                    {/* Replenishement Quantity in Quarts Text Field */}
                    <TextField
                        id="replenished-quantity"
                        label="Replenished Quantity"
                        variant="outlined"
                        type="number"
                        inputProps={{ inputMode: 'decimal'}}
                        onChange={replenishedQuantityChangedHandler}
                        value={replenishedQuantity ?? ''}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">Quarts</InputAdornment>
                        }}
                    />

                    <Button
                        variant="contained"
                        onClick={() => {
                            calcualteReplenishment();
                            handleClickOpen();
                        }}>Calculate</Button>
                </Stack>
                <Dialog
                    open={dialogState}
                    onClose={handleDialogClose}
                    keepMounted
                >
                    <DialogTitle>
                        Replenished Oil
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {generateText()}
                        </DialogContentText>
                    </DialogContent>
                    <Button onClick={handleDialogClose}>Close</Button>
                </Dialog>
            </Container>
        </>
    );
}