import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default function SignInWithEmailAndPass({ email, password }: { email: string, password: string }) {
    const auth = getAuth()
    console.log("Signing in ")
    signInWithEmailAndPassword(auth, email, password).then((userCred) => {
        const user = userCred.user;
        console.log(user.uid)


    }).catch((error) => {
        throw error;
    })
}