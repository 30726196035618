import { TableBody, TableCell, TableRow } from "@mui/material";
import { RowDataModel, RowModelTypes } from "../../models/tablerowdatamodel";
import unixToString from "../dayjs/dayjsformat";
import { SortTableViewModel } from "../../viewmodel/sort_table_viewmodel";
import { ORDER_TYPE } from "./sort_table";
import { useEffect } from "react";
import dayjs from "dayjs";

export default function SortTableBody({ documents, headCells, sortKey, order, cellClickedCallback }:
    {
        documents: RowDataModel[],
        headCells: RowModelTypes,
        sortKey: keyof RowDataModel,
        order: ORDER_TYPE,
        cellClickedCallback: (key: keyof RowDataModel, tail: string) => void
    }) {
    return (
        <TableBody>
            {documents.sort((a, b) => {
                let result;
                (a[sortKey] > b[sortKey] ? result = 1 : result = -1)

                return (order == ORDER_TYPE.ASC ? result : -result)
            }).map((doc) => {
                return (<TableRow>
                    {headCells.map((key) => {
                        let isClose = false;
                        if (key != 'tail' || 'qti') {
                            isClose = ((dayjs().unix() - (doc[key] as number)) / 3600) < 2

                        }

                        return (
                            <TableCell sx={{backgroundColor: isClose ? "#FFBBBB" : "#FFFFFF"}} onClick={() => {
                                cellClickedCallback(key, doc.tail)
                            }}>
                                {((key != 'tail') && key != 'qti') ? unixToString(doc[key] as number) : doc[key]}
                            </TableCell>
                        )
                    })}
                </TableRow>)
            })}
        </TableBody>
    )
}