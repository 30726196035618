import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Icon, IconButton, Stack, Typography } from "@mui/material";
import { RowDataModel } from "../models/tablerowdatamodel";
import SortTable from "./SortTable/sort_table";
import { Delete } from "@mui/icons-material";

export default function ServicingDialog(
    { dialogState, onClose, document, onDelete }:
        {
            dialogState: boolean,
            onClose: () => void,
            document: RowDataModel,
            onDelete: (document: RowDataModel) => void
        }
) {
    return (
        <Dialog open={dialogState} onClose={onClose}>
            {/* title */}
            <DialogTitle>
                <Grid width={"100%"} display={'flex'} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h5">
                        {document?.tail}
                    </Typography>
                    <IconButton onClick={() => onDelete(document)}>
                        <Delete />
                    </IconButton>
                </Grid>
            </DialogTitle>

            {/* content */}
            <DialogContent>
                <Stack alignItems={'center'} spacing={1}>
                    <SortTable documents={[document]} isDataLoading={false} handleBodyCellClicked={() => { }} props={{ padding: "checkbox" }} />
                    <ButtonGroup fullWidth variant="outlined">
                        <Button>POS</Button>
                        <Button>BOS</Button>
                        <Button>IOS</Button>
                    </ButtonGroup>
                    <Button fullWidth variant="outlined">
                        Reset QTI
                    </Button>
                    <Button fullWidth variant="outlined" color="warning">
                        Invalidate
                    </Button>
                    <Divider />
                    <Button fullWidth variant="outlined" color="inherit">Update</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )

}