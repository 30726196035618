import { Alert, Box, Button, ButtonGroup, Container, Dialog, DialogContent, DialogTitle, Divider, Fab, Snackbar, Stack, Typography } from "@mui/material";
import SortTable from "../components/SortTable/sort_table";
import { SortTableViewModel } from "../viewmodel/sort_table_viewmodel";
import { RowDataModel } from "../models/tablerowdatamodel";
import React, { useEffect, useState } from "react";
import ServicingDialog from "../components/servicing_levels_update_dialog";
import { getDocs, onSnapshot } from "firebase/firestore";
import AddIcon from '@mui/icons-material/Add';
import AddAircraftTableDialog from "../components/add_aicraft_to_table_dialog";


export default function ServicingTimes() {
    const viewmodel: SortTableViewModel = new SortTableViewModel().getInstance();

    const [documents, setDocuments] = React.useState<RowDataModel[]>([]);
    const [isDataLoading, setIsDataLoading] = React.useState<boolean>(false);

    const [dialogState, setDialogState] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState<RowDataModel>();

    const handleBodyCellClicked = (key: keyof RowDataModel, tail: string) => {
        documents.map((doc) => {
            if (doc.tail == tail) {
                setSelectedDoc(doc)
                setDialogState(true);
            }
        })
    }

    const [shouldShowSnackbar, setShouldShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const handleOnDelete = (docToDelete: RowDataModel) => {
        viewmodel.deleteDocumentByID(docToDelete.tail).then(() => {
            setDialogState(false);

            documents.forEach((doc, index) => {
                // find the index of the doc to remove
                if (doc.tail == docToDelete.tail) {
                    // remove the doc at the index we found
                    documents.splice(index, 1)

                    // notify doc deleted
                    setShouldShowSnackbar(true);
                    setSnackbarMessage(`Deleted aircraft ${doc.tail}`)
                } else {
                    // notify couldnt delete doc
                    setShouldShowSnackbar(true);
                    setSnackbarMessage("Couldnt delete selected aircraft.")
                }
            })

        });
    }

    const handleCloseSnackbar = () => {
        setShouldShowSnackbar(false);
        setSnackbarMessage('');
    }

    const [addAircarftDialogState, setAddAircarftDialogState] = useState(false)

    const handleAddClicked = () => {
        setAddAircarftDialogState(true);
    }

    const handleAddAircraftOnSubmit = (data: RowDataModel) => {
        viewmodel.addDocument(data).then(() => {
            // fullfilled
            setAddAircarftDialogState(false);
        }).catch((reason) => {

        })
    }

    useEffect(() => {
        setIsDataLoading(true);
        async function fetchData() {
            try {
                await viewmodel.getDataTableFromFirestore().then((rowDataArray) => {
                    setDocuments(rowDataArray);
                    setIsDataLoading(false);
                })
            } catch (error) {
                throw error
            }
        }

        fetchData();
    }, [])


    return (
        <Box padding={0}>
            <SortTable documents={documents} isDataLoading={isDataLoading} handleBodyCellClicked={handleBodyCellClicked} props={{ padding: 'normal' }} />
            {
                selectedDoc ? <ServicingDialog dialogState={dialogState} document={selectedDoc} onClose={() => { setDialogState(false) }} onDelete={handleOnDelete} /> : <div></div>
            }


            <Snackbar open={shouldShowSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                <Alert sx={{ width: '100%' }} onClose={handleCloseSnackbar} severity="success">{snackbarMessage}</Alert>
            </Snackbar>

            {/* add aircraft fab */}
            <Fab color="primary" aria-label="add" sx={{ position: "absolute", bottom: 32, right: 32 }} onClick={handleAddClicked}>
                <AddIcon />
            </Fab>
            <AddAircraftTableDialog open={addAircarftDialogState} onClose={() => { setAddAircarftDialogState(false) }} onSubmit={handleAddAircraftOnSubmit} />
        </Box>
    )
}