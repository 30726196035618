import { Container, Typography } from "@mui/material";

export default function FouroFour() {
    return (
        <Container>
            <Typography>
                404
            </Typography>
        </Container>
    )
}