export function IsHebrew(text: string): boolean {
    let stateValue = false;
    "א ב ג ד ה ו ז ח ט י כ ל מ נ ס ע פ צ ק ר ש ת".split(' ').map((letter) => {
        if (text.includes(letter)) {
            stateValue = true
        } else {
            stateValue = false
        }
    })

    return stateValue;
}