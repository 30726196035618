import { Box, Button, ButtonBase, Card, Container, Dialog, Stack, TextField, ThemeProvider, Typography, colors, styled } from "@mui/material";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { useEffect, useMemo, useState } from "react";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import pages from "../constants/pages";
import myTheme from "../components/theme";
import authPages from "../constants/auth-pages";
import Page from "../models/pages";
import FouroFour from "./notfound";
import { getAuth } from "firebase/auth";
import Home from "./home";

export default function Main() {
    const [currentPage, setCurrentPage] = useState<JSX.Element>();
    const [currentPageName, setCurrentPageName] = useState<string>('');
    const [pagesList, setPagesList] = useState<Page[]>(authPages);
    const location = useLocation();
    const navigate = useNavigate();
    const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

    let pageFound = false

    useEffect(() => {
        getAuth().onAuthStateChanged(user => {
            if (user == null) {
                navigate('/signin')
                setPagesList(authPages);
                authPages.map((page) => {
                    if (page.href == location.pathname) {
                        setCurrentPage(page.element ?? <FouroFour />)
                        setCurrentPageName(page.name)
                    } else {
                        setCurrentPage(<FouroFour />)
                        setCurrentPageName("404")
                    }
                })
            } else {
                setPagesList(pages);
                pages.map((page) => {
                    if (page.href == location.pathname) {
                        setCurrentPage(page.element ?? <FouroFour />)
                        setCurrentPageName(page.name)
                        pageFound = true
                    }
                })
                if (!pageFound) {
                    navigate("/")
                }
            }
        })
    }, [location.pathname]
    )

    return (
        <ThemeProvider theme={myTheme}>
            <Stack>
                <ResponsiveAppBar pagesList={pagesList} location={currentPageName} />
                <Offset />
                <Box>
                    {currentPage}
                </Box>
            </Stack>
        </ThemeProvider>
    )
}
