import { collection, doc, getDocs, limit, orderBy, query, onSnapshot, Query } from "firebase/firestore";
import { firestoreDB } from "../firebase";

export async function GetGeneralTrackingDocs() {
    const querySnapshot = query(collection(firestoreDB, "116/generaltracking/generaltracking"), orderBy("timestamp", 'desc'));

    return getDocs(querySnapshot);
}

export default async function getGeneralTrackingDocsQuery() {

    return query(collection(firestoreDB, "116/generaltracking/generaltracking"), orderBy("timestamp", 'desc'))
}