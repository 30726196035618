import { getDoc, getDocs } from "firebase/firestore";
import GetAllAircraftServicing from "../usecases/getAllAircraftServicing";
import { RowDataModel } from "../models/tablerowdatamodel";
import { FirestoreToRowModelConverter } from "../components/firestore_to_row_model";
import deleteServicingDocument from "../usecases/deleteServicingDocument";
import addRowDocument from "../usecases/addRowDocument";

let instance: SortTableViewModel;

export class SortTableViewModel {

    constructor() {
        if (instance) {
            return instance;
        } else {
            instance = this;
        }
    }

    getInstance() {
        return this;
    }


    async getDataTableFromFirestore(): Promise<RowDataModel[]> {
        let doc: RowDataModel[] = [];

        await getDocs(GetAllAircraftServicing()).then((snapshot) => {
            snapshot.forEach((document) => {
                doc.push(FirestoreToRowModelConverter(document.data(), document.id) as RowDataModel)
            })
            return new Promise((resolve, reject) => {
                resolve(doc);
                reject("error");
            });
        }).catch((error) => { throw error })

        return new Promise((resolve, reject) => {
            resolve(doc);
            reject("error");
        });
    }

    async deleteDocumentByID(id: string) {
        return deleteServicingDocument(id)
    }

    async addDocument(data: RowDataModel) {
        return addRowDocument(data)
    }
}  