import { Card, CardActions, CardContent, Container, List, ListItem, Stack, Typography } from "@mui/material";
import { MMPairModel } from "../models/MMPairModel";

export default function MMPairsCard({ model }: { model: MMPairModel }) {
    return (
        <Card variant="outlined" sx={{ width: "100%" }}>
            <CardContent sx={{ padding: '16px', '&:last-child': { pb: 2 } }}>
                <Typography
                    fontWeight={"750"}
                    gutterBottom>
                    {model.name}
                </Typography>
                <Typography>
                    {model.id}
                </Typography>
            </CardContent>
        </Card>
    )
}