// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyBFj9gtxIAZXNxg-4Lpy9OMrLdkg_0N_c4",
    authDomain: "squadron-116.firebaseapp.com",
    projectId: "squadron-116",
    storageBucket: "squadron-116.appspot.com",
    messagingSenderId: "478008696569",
    appId: "1:478008696569:web:ad963f9a070aaf7a9e4c28"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const firestoreDB =
    initializeFirestore(firebaseApp, {
        ignoreUndefinedProperties: true
    });

const auth = getAuth(firebaseApp);
